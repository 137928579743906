import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from "react"
import Layout from "../components/Layout"
import MetaHead from "../components/MetaHead"
import {graphql} from "gatsby"

const About = ({data, pageContext, path}) => {
	const essentialData = data.contentfulEssentialData
	const document = JSON.parse(data.contentfulAbout.content.raw)

	return (
		<>
			<MetaHead meta={data.contentfulMetaFields} lang={pageContext.langKey} path={path} />
			<Layout essentialData={{...essentialData, ...pageContext, path}}>
				<div className="about">
					<h1>{data.contentfulEssentialData.aboutTitle}</h1>
					{documentToReactComponents(document)}
				</div>
			</Layout>
		</>
	);
}
 
 
export const query = graphql`
query($langKey: String!){
	contentfulAbout(node_locale: {eq: $langKey}) {
		content{
			raw
		}
	}
	contentfulEssentialData(node_locale: {eq: $langKey}) {
		workTitle
		resourcesTitle
		footerCopyright
		footerContact
		blogTitle
		aboutTitle
		homeTitle
		imprintTitle
	}
	contentfulMetaFields(identifier: {eq: "about"}, node_locale: {eq: $langKey}) {
		title
		ogType
		description {
			description
		}
	}
}`


export default About;